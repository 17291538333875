.list-item {
  background: transparent;
  border: none;
  color: var(--white);
  font-family: inherit;
  display: grid;
  grid-template-columns: auto 1fr auto;
  column-gap: 16px;
  cursor: pointer;
  margin-bottom: 32px;
  padding: 0;
  text-align: left;
  width: 100%;
}
.list-item:focus {
  outline: none;
}
.list-item:last-child {
  margin-bottom: 0;
}
.list-item__img {
  background: var(--white);
  border-radius: 4px;
  height: 40px;
  object-fit: cover;
  position: relative;
  width: 40px;
}
.list-item__bank-name {
  font-size: 0.95rem;
  margin: 1px 0 4px;
}
.list-item__ussd {
  color: var(--near-white);
  font-size: 0.875rem;
}
.list-item__arrow-container {
  align-items: center;
  display: flex;
  padding-top: 12px;
}
